<script setup>
import { computed, defineProps } from 'vue';
import { useStore } from 'vuex';
import Checkbox from '@/components/elements/checkbox/Checkbox';

const store = useStore();
const mapData = computed(() => props.data?.sub_items.map((i) => i.id));
const goalsSelected = computed(() => {
	const s = store.getters.goalsSelected;
	return (s || []).map((i) => i.id);
});
const props = defineProps(['data', 'foldersSelected']);

const checkStatus = (goal) => {
	return goalsSelected.value.find((i) => +i === +goal.id);
};

const checkParent = (goals, isAll) => {
	store.dispatch('selected', { goals, isAll });
};
const checkChanged = (goals, isAll) => {
	store.dispatch('selected', { goals, isAll });
	let contains = mapData.value.some((element) =>
		goalsSelected.value.includes(element)
	);
	let containAll = mapData.value.every((element) =>
		goalsSelected.value.includes(element)
	);
	if (!contains) checkParent([props.data], isAll);
	if (containAll) checkParent([props.data], isAll);
};
</script>
<template>
	<table
		class="
			min-w-full
			divide-y
			text-neutral-200
			ml-16
			mb-5
			border-table
			bg-white
		"
	>
		<thead class="bg-neutral-100 h-16">
			<tr>
				<th
					scope="col"
					class="
						px-6
						py-3
						text-left text-xs
						font-semibold
						text-neutral-600
						uppercase
						tracking-wider
						w-16
						whitespace-nowrap
					"
				></th>
				<th
					scope="col"
					class="
						px-6
						py-3
						text-left text-xs
						font-semibold
						text-neutral-600
						uppercase
						tracking-wider
						w-16
						whitespace-nowrap
					"
				></th>
				<th
					scope="col"
					class="
						px-6
						py-3
						text-left text-xs
						font-semibold
						text-neutral-600
						uppercase
						tracking-wider
						w-16
						whitespace-nowrap
					"
				>
					STT
				</th>
				<th
					scope="col"
					class="
						px-6
						py-3
						text-left text-xs
						font-semibold
						text-neutral-600
						uppercase
						tracking-wider
						whitespace-nowrap
					"
				>
					Mục tiêu cụ thể
				</th>
			</tr>
		</thead>
		<tbody style="border-top: none">
			<tr
				v-for="(g, i) in props.data?.sub_items"
				:key="g.id"
				class="bg-white text-neutral-700 border-table"
			>
				<td class="px-6 py-4 w-16">
					<div class="w-4">
						<img
							class="w-full"
							src="@/assets/images/icons/child-goal.png"
							alt=""
						/>
					</div>
				</td>
				<td class="px-6 py-4 w-16">
					<Checkbox
						:disabled="
							props.foldersSelected?.length > 0 ? true : false
						"
						:value="checkStatus(g)"
						@click="
							!props.foldersSelected?.length &&
								checkChanged([g], false)
						"
					/>
				</td>
				<td class="px-6 py-4 whitespace-nowrap text-neutral-700">
					<div class="ml-2">{{ i + 1 }}</div>
				</td>
				<td class="px-6 py-4 text-neutral-700">
					{{ g?.name }}
				</td>
			</tr>
		</tbody>
	</table>
</template>
<style scoped>
.border-table {
	border: 1px solid #f1f5f9;
	border-collapse: collapse;
}
</style>
