<template>
	<DaModalFrame v-model:open="modalIsOpen">
		<active-contract
			@close="modalIsOpen = false"
			:id="centerId"
			@success="reloadPage"
		></active-contract>
	</DaModalFrame>

	<div class="__report-lesson px-12 md-down:px-4 pb-30">
		<div
			class="
				__report-lesson__header
				pt-10
				pb-10
				flex flex-nowrap
				justify-between
				items-center
			"
		>
			<div
				class="
					__record__title
					text-neutral-500
					font-semibold
					whitespace-nowrap
					flex flex-nowrap
					items-center
					text-4xl
				"
			>
				<svg
					class="mr-3 h-6 w-6"
					viewBox="0 0 28 28"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M0.0867744 6.22202C0.440527 4.66346 1.83439 3.5 3.5 3.5H24.5C26.1656 3.5 27.5595 4.66346 27.9132 6.22202L14 14.7245L0.0867744 6.22202ZM0 8.2199V20.651L10.1556 14.4261L0 8.2199ZM11.8325 15.4508L0.335774 22.4978C0.897057 23.6815 2.10294 24.5 3.5 24.5H24.5C25.8971 24.5 27.1029 23.6815 27.6642 22.4978L16.1675 15.4508L14 16.7755L11.8325 15.4508ZM17.8444 14.4261L28 20.651V8.2199L17.8444 14.4261Z"
						fill="#65758B"
					/>
				</svg>

				Gói dịch vụ
			</div>
		</div>
		<div v-if="!isLoading">
			<div class="mb-10" v-if="alertShow">
				<div
					class="
						bg-yellow-50
						border-l-4 border-yellow-600
						p-4
						rounded-md
					"
				>
					<div class="flex justify-between gap-4">
						<div class="flex items-center gap-4">
							<div>
								<img
									src="@/assets/images/logo/logo.svg"
									alt="luca-logo"
									height="24"
									width="35"
								/>
							</div>
							<div class="pt-1 text-neutral-600 text-sm">
								Gói đăng ký của {{ centerName }} sẽ hết hạn sau
								{{ dayLeft }}
								ngày nữa. Quý thầy cô vui lòng điền thông tin
								gia hạn
								<a
									href="https://docs.google.com/forms/d/1wbi3QVCwCLJV5odp3JE0S14BZUNMpvs8y9NtmTbVGpk/edit"
									class="underline text-green-500 font-medium"
									target="_blank"
									rel="noopener"
									>tại đây</a
								>
								để Luca hỗ trợ đăng ký gói mới.
							</div>
						</div>
						<div>
							<svg
								width="12"
								height="12"
								viewBox="0 0 12 12"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
								class="cursor-pointer"
								@click="alertShow = false"
							>
								<path
									fill-rule="evenodd"
									clip-rule="evenodd"
									d="M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L6 4.58579L10.2929 0.292893C10.6834 -0.0976311 11.3166 -0.0976311 11.7071 0.292893C12.0976 0.683417 12.0976 1.31658 11.7071 1.70711L7.41421 6L11.7071 10.2929C12.0976 10.6834 12.0976 11.3166 11.7071 11.7071C11.3166 12.0976 10.6834 12.0976 10.2929 11.7071L6 7.41421L1.70711 11.7071C1.31658 12.0976 0.683417 12.0976 0.292893 11.7071C-0.0976311 11.3166 -0.0976311 10.6834 0.292893 10.2929L4.58579 6L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893Z"
									fill="#E5A42B"
								/>
							</svg>
						</div>
					</div>
				</div>
			</div>
			<div class="mb-10" v-if="hasNewContract">
				<div
					class="
						bg-yellow-50
						border-l-4 border-yellow-600
						p-4
						rounded-md
					"
				>
					<div class="relative">
						<div class="flex items-center gap-4">
							<div>
								<img
									src="@/assets/images/logo/logo.svg"
									alt="luca-logo"
									height="24"
									width="35"
								/>
							</div>
							<div class="pt-1 text-neutral-600 text-sm">
								Gói dịch vụ đăng ký với Luca Education đã kết
								thúc. Vui lòng kích hoạt gói đăng ký mới
								<span
									@click="modalIsOpen = true"
									class="
										underline
										text-green-500
										font-medium
										cursor-pointer
									"
									>tại đây.</span
								>
							</div>
						</div>
						<div
							class="top-1 right-2 absolute cursor-pointer"
							@click="hasNewContract = false"
						>
							<svg
								width="12"
								height="12"
								viewBox="0 0 12 12"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									fill-rule="evenodd"
									clip-rule="evenodd"
									d="M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L6 4.58579L10.2929 0.292893C10.6834 -0.0976311 11.3166 -0.0976311 11.7071 0.292893C12.0976 0.683417 12.0976 1.31658 11.7071 1.70711L7.41421 6L11.7071 10.2929C12.0976 10.6834 12.0976 11.3166 11.7071 11.7071C11.3166 12.0976 10.6834 12.0976 10.2929 11.7071L6 7.41421L1.70711 11.7071C1.31658 12.0976 0.683417 12.0976 0.292893 11.7071C-0.0976311 11.3166 -0.0976311 10.6834 0.292893 10.2929L4.58579 6L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893Z"
									fill="#E5A42B"
								/>
							</svg>
						</div>
					</div>
				</div>
			</div>
			<div class="w-full mb-10">
				<div class="border-b border-neutral-200 md-down:hidden">
					<nav class="-mb-px flex space-x-8 tabs" aria-label="Tabs">
						<div
							v-for="tab in tabsMenu"
							:key="tab.id"
							class="
								border-transparent
								hover:text-yellow-700 hover:border-yellow-500
								whitespace-nowrap
								py-3
								px-1
								border-b-2
								font-semibold
							"
							:class="
								tabSelected === tab.id
									? 'text-yellow-700 border-yellow-500'
									: 'text-neutral-500'
							"
							@click="tabSelected = tab.id"
						>
							<div class="flex items-center gap-2 cursor-pointer">
								<div>{{ tab.name }}</div>
							</div>
						</div>
					</nav>
				</div>
			</div>
			<div v-show="tabSelected === 0">
				<div class="bg-white p-6 rounded-lg shadow-sm text-gray-500">
					<div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
						<div
							class="
								p-4
								border
								lg:rounded-l-lg
								md:rounded-tl-lg
								border-neutral-200
								w-full
								md-down:rounded-t md-down:rounded-b-none
							"
						>
							<div>Gói dịch vụ đăng ký</div>
							<div
								class="text-4xl font-bold text-neutral-600 mt-2"
							>
								{{ active_contract?.package }}
							</div>
						</div>
						<div
							class="
								p-4
								md:border-t
								border-b border-r
								md-down:border-l
								border-neutral-200
								w-full
							"
						>
							<div>Tổng số học sinh</div>
							<div
								class="text-4xl font-bold text-neutral-600 mt-2"
							>
								<span v-if="active_contract.seats">
									{{
										Number(
											active_contract.seats +
												active_contract.bonus_seats
										)
									}}
								</span>
							</div>
						</div>
						<div
							class="
								p-4
								md:border-t md:border-b
								md-down:border-l md-down:border-r
								border-neutral-200
								w-full
							"
						>
							<div>Số học sinh chưa sử dụng</div>
							<div
								class="text-4xl font-bold text-neutral-600 mt-2"
							>
								<span v-if="active_contract.seats">
									{{
										Number(
											active_contract.seats +
												Number(
													active_contract.bonus_seats ||
														0
												) -
												total_student_of_centers
										)
									}}
								</span>
							</div>
						</div>
						<div
							class="
								p-4
								border
								lg:rounded-r-lg
								md:rounded-br-lg
								border-neutral-200
								w-full
								md-down:rounded-b md-down:rounded-t-none
							"
						>
							<div>Kết thúc sau</div>
							<div
								class="text-4xl font-bold text-sienna-600 mt-2"
								v-if="active_contract.day_left"
							>
								{{ active_contract.day_left }} ngày
							</div>
							<div v-else>-</div>
						</div>
					</div>
					<div class="grid grid-cols-2 mt-6 gap-4">
						<div>
							<div class="font-semibold text-neutral-400">
								Số học sinh đăng ký
							</div>
							<div class="text-neutral-900 mt-2">
								{{ active_contract.seats }}
							</div>
						</div>
						<div>
							<div class="font-semibold text-neutral-400">
								Số học sinh tặng thêm (10%)
							</div>
							<div class="text-neutral-900 mt-2">
								{{ active_contract.bonus_seats }}
							</div>
						</div>
						<div>
							<div class="font-semibold text-neutral-400">
								Ngày bắt đầu
							</div>
							<div class="text-neutral-900 mt-2">
								<span v-if="active_contract.start_date">
									{{
										$filters.formatDate2(
											active_contract.start_date
										)
									}}</span
								>
							</div>
						</div>
						<div>
							<div class="font-semibold text-neutral-400">
								Ngày kết thúc
							</div>
							<div class="text-neutral-900 mt-2">
								<span v-if="active_contract.end_date">
									{{
										$filters.formatDate2(
											active_contract.end_date
										)
									}}</span
								>
							</div>
						</div>
					</div>
				</div>
				<div
					class="shadow-sm sm:rounded-2xl overflow-hidden mt-10"
					v-if="number_students_of_orgs.length"
				>
					<div class="__record__table overflow-x-auto scroll-bar">
						<div
							class="
								overflow-hidden
								sm:rounded-t-2xl
								w-fit
								min-w-full
							"
						>
							<table class="min-w-full divide-y text-neutral-200">
								<thead class="bg-neutral-100 h-16">
									<tr>
										<th
											scope="col"
											class="
												px-3
												py-3
												text-xs
												font-semibold
												text-neutral-600
												uppercase
												tracking-wider
												whitespace-nowrap
												text-left
											"
										>
											CƠ SỞ GIÁO DỤC
										</th>
										<th
											scope="col"
											class="
												px-3
												py-3
												text-right text-xs
												font-semibold
												text-neutral-600
												uppercase
												tracking-wider
												whitespace-nowrap
											"
										>
											Học sinh đang kích hoạt
										</th>
									</tr>
								</thead>
								<tbody>
									<tr
										v-for="(
											item, index
										) in number_students_of_orgs"
										:key="item.id"
										:class="
											index % 2 === 0
												? 'bg-white'
												: 'bg-neutral-50'
										"
										class="text-neutral-700"
									>
										<td
											class="
												px-3
												py-4
												whitespace-nowrap
												text-neutral-700
											"
										>
											<div>
												{{ item?.org_name }}
											</div>
										</td>
										<td
											class="
												px-3
												py-4
												whitespace-nowrap
												text-neutral-700 text-right
											"
										>
											<div>
												{{ item?.number_of_students }}
												học sinh
											</div>
										</td>
									</tr>
									<tr class="bg-neutral-50 text-neutral-700">
										<td
											class="
												px-3
												py-4
												whitespace-nowrap
												text-neutral-700
												uppercase
												font-semibold
												text-sm
											"
										>
											<div>
												Tổng số học sinh đã sử dụng
											</div>
										</td>
										<td
											class="
												px-3
												py-4
												whitespace-nowrap
												text-neutral-700 text-right
												uppercase
												font-semibold
												text-sm
											"
										>
											<div>
												{{ total_student_of_centers }}
												học sinh
											</div>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
			<div v-show="tabSelected === 1">
				<div
					class="__report-lesson__container"
					v-if="data && data.length > 0"
				>
					<div class="shadow-sm sm:rounded-2xl overflow-hidden">
						<div class="__record__table overflow-x-auto scroll-bar">
							<div
								class="
									overflow-hidden
									sm:rounded-t-2xl
									w-fit
									min-w-full
								"
							>
								<table
									class="min-w-full divide-y text-neutral-200"
								>
									<thead class="bg-neutral-100 h-16">
										<tr>
											<th
												scope="col"
												class="
													px-3
													py-3
													text-xs
													font-semibold
													text-neutral-600
													uppercase
													tracking-wider
													whitespace-nowrap
													text-left
												"
											>
												NGÀY GIAO DỊCH
											</th>
											<th
												scope="col"
												class="
													px-3
													py-3
													text-left text-xs
													font-semibold
													text-neutral-600
													uppercase
													tracking-wider
													whitespace-nowrap
												"
											>
												Gói đăng ký
											</th>
											<th
												scope="col"
												class="
													px-3
													py-3
													text-left text-xs
													font-semibold
													text-neutral-600
													uppercase
													tracking-wider
													whitespace-nowrap
												"
											>
												Sô h.s Đăng ký
											</th>
											<th
												scope="col"
												class="
													px-3
													py-3
													text-left text-xs
													font-semibold
													text-neutral-600
													uppercase
													tracking-wider
													whitespace-nowrap
												"
											>
												GHI CHÚ
											</th>
											<th></th>
										</tr>
									</thead>
									<tbody>
										<tr
											v-for="(item, index) in data"
											:key="item.id"
											:class="
												index % 2 === 0
													? 'bg-white'
													: 'bg-neutral-50'
											"
											class="text-neutral-700"
										>
											<td
												class="
													px-3
													py-4
													whitespace-nowrap
													text-neutral-700
												"
											>
												<div v-if="item?.apply_date">
													{{
														$filters.formatDate2(
															item?.apply_date
														)
													}}
												</div>
												<div v-else>-</div>
											</td>
											<td
												class="
													px-3
													py-4
													whitespace-nowrap
													text-neutral-700
												"
											>
												<div>
													{{ item?.invoice_number }}
												</div>
											</td>
											<td
												class="
													px-3
													py-4
													whitespace-nowrap
													text-neutral-700
												"
											>
												<div>
													{{ item?.seats }}
												</div>
											</td>
											<td
												class="
													px-3
													py-4
													whitespace-nowrap
													text-neutral-700
												"
											>
												<div>
													{{ item?.note }}
												</div>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
				<div
					v-else
					class="
						w-full
						h-full
						flex
						items-center
						justify-center
						flex-col
					"
				>
					<img
						src="@/assets/images/illustrations/not_found.png"
						alt=""
						height="250"
						width="250"
					/>
					<div
						class="text-neutral-500 text-lg text-center"
						style="max-width: 700px"
					>
						Không có hoá đơn
					</div>
				</div>
			</div>
		</div>
		<div v-else class="fixed top-1/2 left-1/2">
			<Loading />
		</div>
	</div>
</template>

<script>
import { $api } from '@/services';
import Loading from '@/components/common/Loading';
import DaModalFrame from '@/components/common/daModalFrame/DaModalFrame.vue';
import ActiveContract from '@/views/packages/activeContract.vue';

export default {
	name: 'Packages',
	components: { Loading, DaModalFrame, ActiveContract },
	data() {
		return {
			active_contract: {},
			data: [],
			number_students_of_orgs: [],
			total_student_of_centers: 200,
			tabsMenu: [
				{
					id: 0,
					name: 'Chi tiết gói dịch vụ',
				},
				{
					id: 1,
					name: 'Lịch sử giao dịch',
				},
			],
			tabSelected: 0,
			params: {},
			isLoading: true,
			alertShow: false,
			modalIsOpen: false,
		};
	},

	created() {
		this.fetchContactDetail();
		if (this.dayLeft > 0 && this.dayLeft <= 7) this.alertShow = true;
	},

	computed: {
		isAccounting() {
			return this.$store.state.profile.staff.role === 'accountant';
		},
		centerName() {
			return this.$store.state.profile.current_org.name;
		},
		dayLeft() {
			return this.$store.state.profile.days_left_in_contract;
		},
		hasNewContract() {
			return this.$store.state.profile.has_new_contract;
		},
		centerId() {
			return this.$store.state.profile.center_id;
		},
	},

	methods: {
		reloadPage() {
			location.reload();
		},

		async fetchContactDetail() {
			try {
				const {
					data: {
						data,
						number_students_of_orgs,
						active_contract,
						total_student_of_centers,
					},
				} = await $api.leader.fetchContact(
					this.params,
					!this.isAccounting
				);
				this.data = data || [];
				this.number_students_of_orgs = number_students_of_orgs || [];
				this.active_contract = active_contract || {};
				this.total_student_of_centers = total_student_of_centers || 0;
			} catch (e) {
				console.log(e);
			} finally {
				this.isLoading = false;
			}
		},
	},
};
</script>
