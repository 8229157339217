<template>
	<div>
		<div class="font-semibold text-lg text-neutral-600 mb-4">
			{{ index + 1 }}. {{ data?.skill?.name }} ({{
				isGroup ? data?.group_goals?.length : data?.goals?.length
			}})
		</div>
		<div class="shadow-sm sm:rounded-2xl overflow-hidden">
			<div class="__record__table overflow-x-auto scroll-bar">
				<div class="overflow-hidden sm:rounded-t-2xl w-fit min-w-full">
					<table class="min-w-full divide-y text-neutral-200">
						<thead class="bg-neutral-100 h-16">
							<tr>
								<th
									class="
										px-6
										py-3
										text-left text-xs
										font-semibold
										text-neutral-600
										uppercase
										tracking-wider
										w-16
										whitespace-nowrap
									"
									scope="col"
								>
									STT
								</th>
								<th
									class="
										px-6
										py-3
										text-left text-xs
										font-semibold
										text-neutral-600
										uppercase
										tracking-wider
										whitespace-nowrap
									"
									scope="col"
									style="min-width: 160px"
								>
									Nội dung
								</th>
							</tr>
						</thead>
						<tbody>
							<tr
								v-for="(g, i) in isGroup
									? data?.group_goals
									: data?.goals"
								:key="g.goal_id || g.id"
								:class="
									i % 2 === 0 ? 'bg-white' : 'bg-neutral-50'
								"
								class="
									text-neutral-700
									hover:bg-orange-50
									cursor-pointer
								"
								@click.stop="
									$router.push(
										`${
											isGroup ? '/group-class' : '/goal'
										}/${studentId}/${g.goal_id || g.id}`
									)
								"
							>
								<td
									class="
										px-6
										py-4
										whitespace-nowrap
										text-neutral-700
									"
								>
									<div class="ml-2">
										{{ i + 1 }}
									</div>
								</td>
								<td class="px-6 py-4 text-neutral-700">
									<div class="flex items-center">
										<div
											class="
												mr-2
												hover:text-yellow-700
												hover:underline
											"
										>
											{{ g.goal_name || g.name }}
										</div>
										<TooltipGoalDetail
											:goal-id="g.goal_id || g.id"
											:is-group="isGroup"
											:student-id="studentId.toString()"
										/>
									</div>
									<div
										class="flex gap-2 flex-wrap"
										style="max-width: 360px"
									>
										<!-- <div
											v-if="g?.raw_source"
											class="
												mt-1
												py-0.5
												px-2.5
												rounded-full
												bg-neutral-200
												text-neutral-700
												h-fit
												w-fit
												font-semibold
												shadow-input
												whitespace-nowrap
											"
											style="font-size: 10px"
										>
											{{ g?.display_source }}
										</div> -->
										<div
											v-if="g?.source"
											class="
												mt-1
												py-0.5
												px-2.5
												rounded-full
												bg-neutral-200
												text-neutral-700
												h-fit
												w-fit
												font-semibold
												shadow-input
												whitespace-nowrap
											"
											style="font-size: 10px"
										>
											{{ g?.source }}
										</div>
										<div
											v-else
											class="
												mt-1
												py-0.5
												px-2.5
												rounded-full
												bg-neutral-200
												text-neutral-700
												h-fit
												w-fit
												font-semibold
												shadow-input
												whitespace-nowrap
											"
											style="font-size: 10px"
										>
											{{
												$filters.sourceMap(
													g?.state,
													g.editable
												)
											}}
										</div>
										<template
											v-if="
												g?.assistance_levels &&
												org.enable_goal_assistance_level
											"
											class="flex gap-2"
										>
											<div
												v-for="ass in g?.assistance_levels"
												:key="ass"
											>
												<div
													class="
														mt-1
														py-0.5
														px-2.5
														rounded-full
														bg-pink-100
														whitespace-nowrap
														text-neutral-700
														h-fit
														w-fit
														font-semibold
														shadow-input
													"
													style="font-size: 10px"
												>
													{{ ass }}
												</div>
											</div>
										</template>
									</div>
									<div class="flex items-center gap-2 mt-2">
										<div v-if="g?.has_media">
											<img
												alt=""
												src="@/assets/images/icons/has_media.png"
											/>
										</div>
										<div
											v-if="g.number_of_assessment > 0"
											class="
												text-neutral-400 text-xs
												font-semibold
											"
										>
											ĐÃ HỌC
											{{ g.number_of_assessment }}
											NGÀY
										</div>
										<div v-if="g?.completed_at">
											<img
												alt=""
												src="@/assets/images/icons/has_taught.png"
											/>
										</div>
									</div>
									<div
										v-if="g.complete_percent > 0"
										class="flex items-center gap-2 mt-2"
									>
										<div
											class="
												text-neutral-400 text-xs
												font-semibold
											"
										>
											KẾT QUẢ
										</div>
										<div
											class="progress-bar overflow-hidden"
										>
											<div
												:style="{
													background:
														$filters.tagResult(
															g.complete_percent
														)?.background,
													width:
														g.complete_percent +
														'%',
												}"
												class="h-full"
											></div>
										</div>
										<div
											class="
												text-neutral-400 text-xs
												font-semibold
											"
										>
											{{ g.complete_percent }}%
										</div>
									</div>
									<div
										v-if="g.parent?.sub_goal_count > 0"
										class="goal-block flex gap-3 mt-2"
									>
										<img
											class="w-3 h-3 mt-1"
											src="@/assets/images/icons/goal-arrow.png"
											alt=""
										/>
										<div>
											<div
												class="
													whitespace-nowrap
													text-neutral-700
												"
											>
												{{ g.parent?.name }}
											</div>
											<div
												class="
													text-neutral-400 text-xs
													font-semibold
												"
											>
												{{ g.parent?.sub_goal_count }}
												MỤC TIÊU
											</div>
										</div>
									</div>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import TooltipGoalDetail from '@/components/elements/tooltipGoalDetail/TooltipGoalDetail.vue';

export default {
	name: 'GoalList',
	components: { TooltipGoalDetail },

	props: {
		index: {
			type: Number,
			required: true,
		},

		isGroup: {
			type: Boolean,
			default: false,
		},

		data: {
			type: Object,
			required: true,
		},

		studentId: {
			type: Number,
			required: true,
		},

		type: {
			type: String,
			default: '',
		},
	},

	computed: {
		org() {
			return this.$store.state.profile.current_org;
		},
	},
};
</script>
<style scoped>
.progress-bar {
	width: 203px;
	height: 6px;
	border-radius: 40px;
	background: #cbd5e1;
}
.goal-block {
	background: #eef3f8;
	padding: 12px;
	border-radius: 8px;
	border: 1px solid #bacde4;
}
</style>
