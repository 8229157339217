
import { computed, defineComponent, ref } from 'vue';
import DaIcon from '@/components/elements/daIcon/DaIcon.vue';
import { useAssignments } from '@/features/assignment/useAssignments';
import { useRoute } from 'vue-router';
import DaModalFrame from '@/components/common/daModalFrame/DaModalFrame.vue';
import DeleteConfirmModal from '@/views/record/studentDetail/features/staffList/features/deleteConfirmModal/DeleteConfirmModal.vue';
import { _MODAL_TYPE } from '@/enums';
import MakeMainStaffModal from '@/views/record/studentDetail/features/staffList/features/makeMainStaffModal/MakeMainStaffModal.vue';
import CreateStaffModal from '@/views/record/studentDetail/features/staffList/features/createStaffModal/CreateStaffModal.vue';
import { useGetEmployees } from '@/views/record/employee/services/getEmployees';
import { useStore } from 'vuex';
import { $api } from '@/services';
import CreateDaycareClass from '@/views/record/studentDetail/features/staffList/features/createDaycareClass/CreateDaycareClass.vue';
import Loading from '@/components/common/Loading.vue';
import ChangedStaffModal from '@/views/record/studentDetail/features/staffList/features/ChangedStaffModal.vue';
import AddGroupClass from '@/views/record/studentDetail/features/staffList/features/AddGroupClass.vue';
import DeleteGroupClassConfirmModal from '@/views/record/studentDetail/features/staffList/features/DeleteGroupClassConfirmModal.vue';

export default defineComponent({
	name: 'StaffList',
	components: {
		DeleteGroupClassConfirmModal,
		AddGroupClass,
		ChangedStaffModal,
		Loading,
		CreateDaycareClass,
		CreateStaffModal,
		MakeMainStaffModal,
		DeleteConfirmModal,
		DaModalFrame,
		DaIcon,
	},
	props: {
		student: {
			type: Object,
			default() {
				return {
					id: '',
					dob: '',
					name: ' ',
					full_name: '',
				};
			},
		},
	},
	setup(props: any) {
		const staffs = ref([] as any[]);
		const isLoading = ref(true);
		const staffsList = ref([] as any[]);
		const store = useStore();
		const {
			getAssignments,
			deleteStaffAssignments,
			createStaffAssignments,
			makeMainStaffAssignments,
		} = useAssignments();
		const { getEmployees } = useGetEmployees();
		const route = useRoute();
		const id = route.params.id ? route.params.id + '' : '';

		const isAdminAndAccountant = computed(
			() => store.getters.isAdminAndAccountant
		);

		const isAdmin = computed(() => store.getters.isAdmin);

		const oldPayload = ref({
			'x-cross': route.query.org_id,
		});

		const is_old_data = ref(route.query.is_old_data === 'true');

		async function fetchAssignment() {
			const res = await getAssignments(
				id,
				isAdminAndAccountant.value,
				is_old_data.value ? oldPayload.value : null
			);
			if (res?.data) {
				staffs.value = res?.data;
			}
		}

		async function fetchStaffs() {
			if (isAdminAndAccountant.value) {
				const res = await getEmployees(
					'',
					is_old_data.value ? oldPayload.value : null
				);
				if (res?.data) {
					staffsList.value = res?.data.filter(
						(item: { state: string }) => {
							return item?.state === 'active';
						}
					);
				}
			}
		}

		let editMode = ref(false);

		function edit() {
			editMode.value = true;
		}

		let modalIsOpen = ref(false);
		let modalType = ref('');
		let dataDelete = ref({
			studentName: '',
			teacherName: '',
			assigmentId: '',
			staffId: '',
		});
		let dataMake = ref({
			staffNew: '',
			staffOle: '',
			studentName: '',
			assigmentId: '',
		});
		let dataGroupClassDelete = ref(null);

		function openDeleteModal(
			id: string,
			staffName: string,
			staffId: string
		) {
			modalType.value = _MODAL_TYPE.DELETE;
			modalIsOpen.value = true;
			dataDelete.value = {
				studentName: props.student?.name,
				teacherName: staffName,
				assigmentId: id,
				staffId,
			};
		}

		function openDeleteGroupClassModal(clazz: any) {
			modalType.value = _MODAL_TYPE.DELETE_GROUP_CLASS;
			modalIsOpen.value = true;
			dataGroupClassDelete.value = {
				...clazz,
				studentName: props.student?.name,
			};
		}

		function openMakeMainStaffModal(id: string, staffName: string) {
			modalType.value = _MODAL_TYPE.MAKE_MAIN_STAFF;
			modalIsOpen.value = true;
			dataMake.value = {
				staffNew: staffName,
				staffOle: '',
				studentName: props.student?.name,
				assigmentId: id,
			};
		}

		function openCreateModal() {
			modalType.value = _MODAL_TYPE.CREATE;
			modalIsOpen.value = true;
		}

		function closeModal() {
			modalType.value = '';
			modalIsOpen.value = false;
			dataDelete.value = {
				studentName: '',
				teacherName: '',
				assigmentId: '',
				staffId: '',
			};
		}

		function save() {
			editMode.value = false;
		}

		async function deleteStaffAssignment(props: any) {
			const { assigmentId, staffId } = props;
			const {
				data: { data },
			} = await $api.schedule.staffHasSchedule({
				staff_id: staffId,
				student_id: id,
			});

			if (data) {
				modalType.value = _MODAL_TYPE.CHANGE_STAFF;
				modalIsOpen.value = true;
				return;
			}
			const res = await deleteStaffAssignments(id, assigmentId);
			if (res?.data) {
				modalIsOpen.value = false;
				await store.dispatch('setAlertMessage', {
					message: 'Xoá giáo viên thành công',
				});
				await fetchAssignment();
			}
		}

		async function createStaffSubmit(value: {
			staff_id: string;
			main: boolean;
		}) {
			const res = await createStaffAssignments(
				id,
				value?.staff_id,
				value?.main
			);
			if (res?.data) {
				modalIsOpen.value = false;
				await fetchAssignment();
				await store.dispatch('setAlertMessage', {
					message: 'Thêm giáo viên thành công',
				});
			}
		}

		async function changeStaffSubmit(value: {
			staff_id: string;
			main: boolean;
		}) {
			try {
				const { data } = await $api.assignment.changeStaffAssignments(
					id,
					dataDelete.value.assigmentId,
					{
						staff_id: dataDelete.value.assigmentId,
						substitute_assignment: {
							staff_id: +value.staff_id,
							student_id: +id,
							is_main: value?.main,
						},
					}
				);
				if (data) {
					modalIsOpen.value = false;
					await store.dispatch('setAlertMessage', {
						message: 'Thay Giáo viên thành công',
					});
					await fetchAssignment();
				}
			} catch (err) {
				await store.dispatch('setAlertMessage', {
					message: 'Cõ lỗi xảy ra, vui lòng thử lại.',
					type: 'danger',
				});
			}
		}

		async function makeMainStaffSubmit(value: {
			assigmentId: string;
			main: boolean;
		}) {
			const res = await makeMainStaffAssignments(id, value?.assigmentId);
			if (res?.data) {
				await fetchAssignment();
				modalIsOpen.value = false;
				await store.dispatch('setAlertMessage', {
					message: 'Áp dụng vai trò Giáo viên phụ trách thành công',
				});
			}
		}

		const classes = ref([]);
		const classList = ref([]);
		const groupClasses = ref([]);
		const groupClassesList = ref([]);

		async function fetchGroupClassList() {
			try {
				const { data } = await $api.groupClasses.list();
				groupClassesList.value = data?.data ?? [];
			} catch (e) {
				console.log(e);
			}
		}

		async function fetchGroupClass() {
			try {
				const { data } = await $api.groupClasses.fetchByStudentId(id);
				groupClasses.value = data?.data ?? [];
				isLoading.value = false;
			} catch (e) {
				console.log(e);
			}
		}

		async function fetchBoardings() {
			try {
				const { data } = await $api.boarding.getDayCareClasses(
					isAdminAndAccountant.value,
					is_old_data.value ? oldPayload.value : null
				);
				classList.value = data?.data;
				isLoading.value = false;
			} catch (e) {
				console.log(e);
			}
		}

		async function fetchDaycareClassByStudent() {
			try {
				const { data } = await $api.student.daycareClasses(
					id,
					isAdminAndAccountant.value,
					is_old_data.value ? oldPayload.value : null
				);
				classes.value = data?.data;
				isLoading.value = false;
			} catch (e) {
				console.log(e);
			}
		}

		async function createGroupClassSubmit(class_id: string) {
			try {
				await $api.groupClasses.addStudentToGroupClass(class_id, {
					student_ids: [+id],
				});
				await store.dispatch('setAlertMessage', {
					message: 'Thêm học sinh vào lớp nhóm thành công',
				});
				await fetchGroupClass();
			} catch (e) {
				console.log(e);
			}
			closeModal();
		}

		async function createDaycareSubmit(class_id: string) {
			try {
				const res = await $api.boarding.memberships(id, class_id);
				if (res) {
					await store.dispatch('setAlertMessage', {
						message: 'Cập nhật lớp bán trú thành công',
					});
					await fetchDaycareClassByStudent();
				}
			} catch (e) {
				console.log(e);
			}
			closeModal();
		}

		async function updateDaycareSubmit(class_id: string) {
			try {
				const res = await $api.boarding.deleteMemberships(
					id,
					// @ts-ignore
					classes.value[0]?.id
				);
				if (res) {
					await $api.boarding.memberships(id, class_id);
					await store.dispatch('setAlertMessage', {
						message: 'Cập nhật lớp bán trú thành công',
					});
					await fetchDaycareClassByStudent();
				}
			} catch (e) {
				console.log(e);
			}
			closeModal();
		}

		async function deleteDaycareSubmit(class_id: string) {
			try {
				const res = await $api.boarding.deleteMemberships(id, class_id);
				if (res) {
					await store.dispatch('setAlertMessage', {
						message: 'Xóa lớp bán trú thành công',
					});
					await fetchDaycareClassByStudent();
				}
			} catch (e) {
				console.log(e);
			}
			closeModal();
		}

		async function deleteGroupClassSubmit(clazz: any) {
			try {
				await $api.groupClasses.addStudentToGroupClass(clazz.id, {
					student_ids: clazz.students
						.filter((item: any) => +item.id !== +id)
						.map((item: any) => item.id),
				});
				await store.dispatch('setAlertMessage', {
					message: 'Xóa học sinh khỏi lớp nhóm thành công',
				});
				await fetchGroupClass();
				closeModal();
			} catch (e) {
				console.log(e);
			}
		}

		const classSelected = ref('');
		const isEditClass = ref(false);

		function openModalDaycare() {
			if (classes.value.length > 0) {
				// @ts-ignore
				classSelected.value = classes.value[0]?.day_care_class_id;
			}
			modalType.value = _MODAL_TYPE.DAYCARECLASS;
			modalIsOpen.value = true;
		}

		function openModalGroupClass() {
			modalType.value = _MODAL_TYPE.ADD_GROUP_CLASS;
			modalIsOpen.value = true;
		}

		const editGroupClass = ref(false);

		fetchAssignment();
		if (!is_old_data.value) {
			fetchStaffs();
			fetchBoardings();
		}
		fetchDaycareClassByStudent();
		fetchGroupClass();
		fetchGroupClassList();

		return {
			isAdmin,
			isAdminAndAccountant,
			staffs,
			editMode,
			modalIsOpen,
			dataDelete,
			dataMake,
			modalType,
			staffsList,
			classes,
			classList,
			classSelected,
			isEditClass,
			modalTypes: computed(() => _MODAL_TYPE),
			isTeacherAndAccountant: computed(
				() => store.getters.isTeacherAndAccountant
			),
			isLoading,
			openModalDaycare,
			createDaycareSubmit,
			changeStaffSubmit,
			updateDaycareSubmit,
			deleteDaycareSubmit,
			edit,
			save,
			openDeleteModal,
			closeModal,
			deleteStaffAssignment,
			openMakeMainStaffModal,
			createStaffSubmit,
			openCreateModal,
			makeMainStaffSubmit,
			is_old_data,
			groupClasses,
			groupClassesList,
			openModalGroupClass,
			createGroupClassSubmit,
			editGroupClass,
			deleteGroupClassSubmit,
			openDeleteGroupClassModal,
			dataGroupClassDelete,
		};
	},
});
